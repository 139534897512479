.card-customize {
  .card-head {
    display: flex;
    justify-content: space-between;
  }

  .ant-card-body {
    padding: 0;
  }
}

.card-total {
  padding: 1rem;

  .ant-card-body {
    padding: 0;
  }

  .detail {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}