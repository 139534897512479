.card-form {
  background-color: $color-blue-light;
  border: 2px solid $color-white-50;
  border-radius: 8px;
}

div.upload-form-box {
  border: 1px solid #cecece;
  border-radius: 8px;
  padding: 4px;
  min-height: 42px;
}

form.form-readonly-white {
  input,
  div.ant-picker,
  div.ant-select-selector {
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }

  label.ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.88);
  }
}