$radius: 4px;

input {
  &.ant-input {
    border-radius: $radius;
  }
}

.ant-radio-button-wrapper:first-child {
  border-start-start-radius: $radius !important;
  border-end-start-radius: $radius !important;
}

.ant-radio-button-wrapper:last-child {
  border-start-end-radius: $radius !important;
  border-end-end-radius: $radius !important;
}

.ant-btn {
  border-radius: $radius !important;
}

.ant-picker {
  border-radius: $radius !important;
}

.ant-modal-confirm-btns {
  .ant-btn-dangerous {
    background-color: $danger;
    color: $color-light;
  }
}

.ant-btn-round {
  border-radius: 24px !important;
}

.ant-upload-list-item {
  background-color: rgba($color-dark, 0.04);
}

.ant-upload-list-item {
  .ant-upload-list-item-action {
    opacity: 1 !important;
  }
}

.ant-layout-sider {
  width: 250px !important;
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
}

span.text-ellipsis-2r {
  line-height: 1em;
  max-height: 2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
}