.layout-container {
  min-height: 100vh;
}

.layout-login {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark;
  background-repeat: no-repeat;
  background-position: center;

  .ant-card-body {
    padding: 5rem;
  }

  .logo,
  .description {
    display: block;
    margin: auto;
    text-align: center;
    font-size: 18px;
  }

  input,
  .ant-input-password {
    font-size: 18px;
    border-radius: 3px;
  }
}

.img-gallery-item > .btn-gallery-delete {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    border-radius: 0;
  }

  .collection {

    .box-collection {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      overflow: hidden;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      &-sm {
        display: flex;
        overflow: hidden;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        margin-top: 2px;
  
        .img {
          width: calc((100% - 10px)/3);
          margin-right: 2px;
  
          &:last-child {
            margin-right: 0;
          }
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
  
      &-detail {
        text-align: center;
        color: $color-light;
  
        .text {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
        }
      }
    }
  }