.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  position: relative;

  &:nth-child(1) {
    margin-top: 0;
  }

  .gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .item-hover {
    cursor: pointer;
  }

  .item-hover .overlay {
    background: rgba(0,0,0,0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  .item-hover:hover .overlay {
    opacity: 1;
  }

  .btitle {
    width: 100%;
  }
}