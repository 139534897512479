@each $name,
$unit in $font-sizes {
  .fs-#{$name} {
    font-size: $unit !important;
  }
}

@each $align in $aligns {
  .text-#{$align} {
    text-align: $align !important;
  }
}

@each $name,
$unit in $theme-colors {
  .text-#{$name} {
    color: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .m-#{$name} {
    margin: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .mx-#{$name} {
    margin-left: $unit !important;
    margin-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .my-#{$name} {
    margin-top: $unit !important;
    margin-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .mt-#{$name} {
    margin-top: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .mb-#{$name} {
    margin-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .ms-#{$name} {
    margin-left: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .me-#{$name} {
    margin-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .p-#{$name} {
    padding: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .py-#{$name} {
    padding-top: $unit !important;
    padding-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .px-#{$name} {
    padding-left: $unit !important;
    padding-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .pt-#{$name} {
    padding-top: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .pb-#{$name} {
    padding-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .ps-#{$name} {
    padding-left: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .pe-#{$name} {
    padding-right: $unit !important;
  }
}