$color-dark: #0A0A09;
$color-dark-50: #7d7c7c;
$color-light: #fff;
$color-blue-light: #f3f4f5;
$color-white-50: #FBFBFB;
$color-primary: #1890FF;
$success: #52C41A;
$muted: #D9D9D9;
$danger: #FF4D4F;

$theme-colors: (
  "success": $success,
  "muted": $muted,
  "danger": $danger,
  "dark-50" : $color-dark-50,
  "primary" : $color-primary,
);


$spacer-default: 1rem;
$spacers: (
  0: 0,
  1: $spacer-default * .25,
  2: $spacer-default * .5,
  3: $spacer-default,
  4: $spacer-default * 1.5,
  5: $spacer-default * 3,
);

$font-size-base: 1rem;
$font-sizes: (
  1: $font-size-base * 2.25,
  2: $font-size-base * 2,
  3: $font-size-base * 1.75,
  4: $font-size-base * 1.5,
  5: $font-size-base * 1.25,
  6: $font-size-base
);

$aligns: center, right, left;