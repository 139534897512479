header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-dark !important;

  img {
    display: block;
  }

  .ant-menu {
    display: flex;
    background-color: transparent;
    color: $color-light;

    &-item {
      width: unset !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    .ant-menu-item-active {
      color: rgba($color-light, .5) !important;
    }
  }

  .profile {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}