* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-size: 16px;
}

.w-100 {
  width: 100%;
  font-family: "Inter";
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.hr {
  display: block;
  height: 1px;
  width: 100%;
  background-color: $muted;
}

.mt-30 {
  margin-top: 30px !important;
}

.btn-danger {
  background-color: $danger;
  color: $color-light;
  border: $danger;
}

.none {
  display: none !important;
}

.small-input {

  &.hide {
    display: none;
  }
}